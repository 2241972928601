import { datetime } from 'rrule';
import { v5 as uuidv5 } from 'uuid';
import { enUS, de, fr } from "date-fns/locale";

export const combineDateAndTime = (date: Date, time: Date) => {
    date = new Date(date);
    date.setHours(date.getHours() + getTimezoneOffset(date), date.getMinutes(), 0, 0);

    const dateAndTime = datetime(
        date.getUTCFullYear(),
        date.getUTCMonth() + 1,
        date.getUTCDate(),
        time.getUTCHours(),
        time.getUTCMinutes(),
        0
    );

    return dateAndTime;
}

export function getTimezoneOffset(date: Date) {
    const timeZoneOffsetMinutes = date.getTimezoneOffset();
    const utcOffsetHours = timeZoneOffsetMinutes / 60;
    const utcPlusHours = -utcOffsetHours;

    return utcPlusHours;
}

export function getISODate(date: Date) {
    return date.toISOString();
}

export function isAhvNumberValid(ahvNumber: string): boolean {
    const ahvNumberValue = ahvNumber.replaceAll(".", "");
    if (ahvNumberValue.length !== 13) {
        return false;
    }

    if (ahvNumberValue.slice(0, 3) !== "756") {
        return false;
    }

    const sumOdd = ahvNumberValue
        .split("")
        .filter((_, i) => i % 2 === 0 && i < 12)
        .reduce((sum, digit) => sum + parseInt(digit), 0);

    const sumEven = ahvNumberValue
        .split("")
        .filter((_, i) => i % 2 !== 0 && i < 12)
        .reduce((sum, digit) => sum + 3 * parseInt(digit), 0);

    const finalSum = sumOdd + sumEven;
    const checkDigit = Math.floor(Math.ceil(finalSum / 10) * 10 - finalSum);

    return checkDigit === parseInt(ahvNumberValue[12]);
}

export function generateNewUuid(existingUuid: string) {
    return uuidv5(uuidv5.URL, existingUuid);
}

export function isUUID(str: string): boolean {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return uuidRegex.test(str);
}

export function getFormattedMinutes(minutes: number) {
    let formattedDiff;
    if (minutes < 60) {
        formattedDiff = `${minutes} min`;
    } else {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        formattedDiff = remainingMinutes === 0 ? `${hours} hrs` : `${hours} hrs ${remainingMinutes} min`;
    }

    return formattedDiff;
}

export function capitalizeFirstLetter(text: string) {
    if (text.length === 0) {
        return text;
    }

    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

export function compareStringDateIfExist(dateToCompare: string, listOfDates: string[]) {
    let dateExist = false;
    let splittedCompareDate = dateToCompare.split('T');
    listOfDates.forEach(date => {
        let splittedListDate = date.split('T');

        if (splittedCompareDate[0] === splittedListDate[0]) {
            dateExist = true;
        }
    });

    return dateExist;
}

export function arrayToString(array: string[]) {
    let text = "";
    text = array.slice(0, -1).join(", ")
    if (array.length > 1) {
        text += ", ";
    }
    text += array.slice(-1);

    return text;
}

export function getDateFnsLocalizer(lang: string) {
    switch (lang) {
        case "en":
            return enUS;
        case "de":
            return de;
        case "fr":
            return fr;

        default:
            return enUS;
    }
}

export function getDateAndTimeFormatByPlatformLanguage(date: Date, plaformLanguage: string) {
    const currentDateFormat: string = getCurrentDateFormat(plaformLanguage);

    const formatter = new Intl.DateTimeFormat(currentDateFormat, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
    });

    return formatter.format(date);
}

export function getDateFormatByPlatformLanguage(date: Date, plaformLanguage: string) {
    const currentDateFormat: string = getCurrentDateFormat(plaformLanguage);

    const formatter = new Intl.DateTimeFormat(currentDateFormat, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });

    return formatter.format(date);
}

function getCurrentDateFormat(plaformLanguage: string) {
    const languageToFormatMapping: { [key: string]: string} = {
        'en': 'en-GB',
        'de': 'de-DE',
        "fr": 'fr-FR'
    };

    return languageToFormatMapping[plaformLanguage] || 'de-DE';
}