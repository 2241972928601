import { Box, Container } from "@mui/material";

import { useParams } from "react-router-dom";
import { createContext, useEffect, useLayoutEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { useStore } from "../../../../../stores/store";
import SessionClientInfo from "./SessionClientInfo";
import SessionDashboard from "./SessionDashboard";
import CrisisAssessmentForm from "../Forms/CrisisAssessmentForm";
import ExcludeClientForm from "../Forms/ExcludeClientForm";
import "../styles.css";
import { SessionStatus } from "../../../../../data/models/session";

export interface SessionOverviewType {
    width: number;
}

export const SessionOverviewContext = createContext<SessionOverviewType>(
    {} as SessionOverviewType
);

function SessionOverview() {
    const { sessionStore, modalStore, authStore } = useStore();
    const { loadAssessorSession, session } = sessionStore;
    const { openModal, closeModal } = modalStore;
    const { user } = authStore;

    const { sessionId } = useParams();
    const [init, setInit] = useState(false);
    const [initExclusion, setInitExclusion] = useState(false);

    const [width, setWidth] = useState(window.innerWidth)
    window.addEventListener('resize', function (event) {
        setWidth(window.innerWidth)
    });

    useLayoutEffect(() => {
        const appContainer = document.querySelector(
            ".app-container"
        ) as HTMLElement;

        const oldValue = appContainer.style.overflowY;
        appContainer.style.cssText = "overflow-y: hidden !important;";

        return () => {
            appContainer.style.overflowY = oldValue;
        };
    }, []);

    useEffect(() => {
        if (sessionId) {
            const fetchData = async () => {
                await loadAssessorSession(sessionId);
            };

            fetchData();
        }

        return () => {
            closeModal();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadAssessorSession, openModal, sessionId]);

    useEffect(() => {
        if (session?.status !== SessionStatus.EMERGENCY_HOLD && 
            session?.status !== SessionStatus.DEACTIVATED && 
            session?.isAssessmentOfSuicideCompleted &&
            !session?.isAssessmentOfSuicideFormCompleted &&
            session.sessionId === sessionId &&
            !init) {
            setInit(true);
            openModal(<CrisisAssessmentForm sessionId={sessionId!} />, { size: "xs" });
        }

        if (session?.status !== SessionStatus.DEACTIVATED &&
            session?.status !== SessionStatus.EMERGENCY_HOLD &&
            // session?.status !== SessionStatus.BLONLY &&
            // session?.status !== SessionStatus.EXCLUDED_SUICIDALITY &&
            session?.isImpairmentCompleted &&
            !session?.isImpairmentFormCompleted &&
            session.sessionId === sessionId &&
            !initExclusion) {
            setInitExclusion(true);
            openModal(<ExcludeClientForm sessionId={sessionId!} />, { size: "xs" });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openModal, session, user]);

    return (
        <SessionOverviewContext.Provider value={{ width }}>
            <Box sx={{ bgcolor: "#f1f4f9", height: "100%", minWidth: "600px", overflowX: "auto" }}>
                <Container
                    maxWidth="xl"
                    sx={{ maxWidth: "none !important" }}
                    className="overview-container"
                >
                    <Box className="survey-data-container">
                        <SessionClientInfo />
                        <Box sx={{ display: "flex", width: "100%", overflowY: "auto" }}>
                            <SessionDashboard />
                        </Box>
                    </Box>
                </Container>
            </Box>
        </SessionOverviewContext.Provider >
    );
}

export default observer(SessionOverview);
