import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CircularProgress,
    Container,
    Grid,
    Paper,
    Typography
} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RoundButton } from "../../_styles/StyledButtons";
import FormInputCheckbox from '../../components/form/FormInputCheckbox';
import FormInputChipSelect from "../../components/form/FormInputChipSelect";
import FormInputDate from "../../components/form/FormInputDate";
import FormInputSelect from "../../components/form/FormInputSelect";
import { FormInputText } from "../../components/form/FormInputText";
import GroupedFormInputChipSelect from "../../components/form/GroupedFormInputChipSelect";
import { assesementTimePoints } from "../../data/static/booking";
import { useStore } from "../../stores/store";
import { SessionVariableRow, SurverVariableRow } from '../../data/models/dataExport';


function DataExportDashboard() {
    const { t } = useTranslation();
    const [surveyRows, setSurveyRows] = useState<SurverVariableRow[]>([]);
    const [sessionRows, setSessionRows] = useState<SessionVariableRow[]>([]);
    const [selectedSurveyRows, setSelectedSurveyRows] = useState<number[]>([]);
    const [selectedSessionRows, setSelectedSessionRows] = useState<number[]>([]);
    const {
        dataExportStore: { getDataExport, getAvailableVariables, loading },
        profileStore: { userTenantList },
        languageStore: { filterLanguageList },
    } = useStore();

    const { control, handleSubmit, setValue, watch } = useForm({
        defaultValues: {
            startDate: new Date(),
            endDate: new Date(),
            assessmentPoints: [],
            languages: [],
            recordType: 'all',
            numberOfRecords: 30,
            institutionIds: [],
            clientId: '',
            isAllTime: false,
        }
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getAvailableVariables();
                formatSurveyRows(data.surveys);
                formatSessionRows(data.sessionVariables);
            } catch (error) {
                console.error("Error fetching available variables:", error);
            }
        };
        fetchData();
    }, [getAvailableVariables]);

    const formatSurveyRows = (surveys: string[]) => {
        const formattedRows = surveys.map((survey, index) => ({ id: index, surveyName: survey }));
        setSurveyRows(formattedRows);
        setSelectedSurveyRows(formattedRows.map(row => row.id));
    };

    const formatSessionRows = (sessionVariables: Record<string, { description: string }>) => {
        const formattedRows: SessionVariableRow[] = Object.keys(sessionVariables).map((key, index) => ({
            id: index,
            variableName: key,
            description: sessionVariables[key].description,
        }));
        setSessionRows(formattedRows);
        setSelectedSessionRows(formattedRows.map(row => row.id));
    };

    const formatRecordData = (data: any) => {
        const { recordType, numberOfRecords, clientId, ...rest } = data;

        if (recordType === "all") {
            return { ...rest };
        }

        if (recordType === "custom") {
            return { recordType, clientId, ...rest };
        }

        if (recordType === 'first' || recordType === 'last') {
            return { recordType, numberOfRecords, ...rest };
        }

        return data;
    };

    const prepareExportPayload = (data: any) => {
        const { startDate, endDate, ...rest } = data;
        const payload: any = {
            ...rest,
            TimezoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
            Surveys: selectedSurveyRows.map(rowId => surveyRows[rowId].surveyName),
            Variables: selectedSessionRows.map(rowId => sessionRows[rowId].variableName),
        };


        if (!isAllTime && recordType !== "custom") {
            payload.From = new Date(startDate).toISOString();
            payload.To = new Date(endDate).toISOString();
        }

        return payload;

    };

    const exportData = async (data: any) => {
        const cleanedPayload = formatRecordData(prepareExportPayload(data));
        try {
            const response = await getDataExport(cleanedPayload);
            const blob = new Blob([response], { type: "text/csv" });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = `result.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Error exporting data:", error);
        }
    };

    const handleSurveyRowSelectionChange = (newSelection: number[]) => setSelectedSurveyRows(newSelection);
    const handleSessionRowSelectionChange = (newSelection: number[]) => setSelectedSessionRows(newSelection);

    const onSubmit = (data: any) => exportData(data);

    const startDate = watch("startDate");
    const endDate = watch("endDate");
    const isAllTime = watch("isAllTime");
    const recordType = watch("recordType");
    const institutionIds = watch("institutionIds");

    const groupedOptions = userTenantList.map(tenant => ({
        group: tenant.name,
        items: tenant.institutions.map(institution => ({
            id: institution.id,
            name: institution.name,
            isDefault: institution.isDefault
        }))
    }));

    useEffect(() => {
        if (startDate > endDate) {
            setValue("endDate", startDate);
        }
    }, [endDate, startDate, setValue]);

    const languageOptions = filterLanguageList.map(language => ({
        label: language.name,
        value: language.alpha2,
    }));

    const isButtonDisabled = () => {
        const numberOfRecords = watch("numberOfRecords");
        const clientId = watch("clientId");

        return (
            loading ||
            institutionIds.length === 0 ||
            (recordType === "first" && (numberOfRecords < 1 || !numberOfRecords)) ||
            (recordType === "last" && (numberOfRecords < 1 || !numberOfRecords)) ||
            (recordType === "custom" && !clientId)
        );
    };

    return (
        <Container maxWidth="lg">
            <Typography variant="h6" sx={{ marginBottom: "1rem", marginTop: "1rem" }}>
                {t("GENERAL_FILTERS")}
            </Typography>
            {recordType !== "custom" && (
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4} order={{ xs: 2, sm: isAllTime ? 2 : 1 }}>
                        {!isAllTime && (
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <FormInputDate name="startDate" control={control} label={t("EXPORT_DATA_FROM")} />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormInputDate name="endDate" control={control} label={t("EXPORT_DATA_TO")} dateLimit={startDate} />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={6} md={isAllTime ? 12 : 2} order={{ xs: 1, sm: isAllTime ? 1 : 2 }}>
                        <FormInputCheckbox
                            control={control}
                            name="isAllTime"
                            defaultValue={false}
                            label={`${t("ALL_TIME")}`}
                        />
                    </Grid>
                </Grid>)
            }
            <Grid container spacing={2} >
                <Grid item xs={12} sm={6} md={2}>
                    <FormInputChipSelect
                        name="assessmentPoints"
                        control={control}
                        label={t("ASSESSMENT_TIME_POINTS")}
                        options={assesementTimePoints}
                        labelKey="name"
                        valueKey="value"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <FormInputChipSelect
                        name="languages"
                        control={control}
                        label={t("GENERAL_LANGUAGES")}
                        options={languageOptions}
                        labelKey="label"
                        valueKey="value"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <GroupedFormInputChipSelect
                        name="institutionIds"
                        label={`${t("SETTINGS_INSTITUTIONS_NAME")} *`}
                        control={control}
                        groupedOptions={groupedOptions}
                        valueKey="id"
                        searchPlaceholder='SEARCH_INSTITUTIONS'
                        search={true}
                        labelKey="name"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <FormInputSelect
                        name="recordType"
                        control={control}
                        label={t("DATA_EXPORT_RECORD")}
                        options={
                            [
                                { label: t("GENERAL_ALL"), value: "all" },
                                { label: t("GENERAL_FIRST"), value: "first" },
                                { label: t("GENERAL_LAST"), value: "last" },
                                { label: t("GENERAL_CUSTOM"), value: "custom" },
                            ]}
                        labelKey="label"
                        valueKey="value"
                    />
                </Grid>
                {recordType !== "custom" && recordType !== "all" && (
                    <Grid item xs={12} sm={6} md={3}>
                        <FormInputText
                            type='number'
                            name="numberOfRecords"
                            control={control}
                            label={`${t("GENERAL_NUM_OF_RECORDS")}*`} />
                    </Grid>
                )}
                {recordType === "custom" && (
                    <Grid item xs={12} sm={6} md={3}>
                        <FormInputText
                            name="clientId"
                            label={`${t("DATA_EXPORT_CLIENT_ID")}*`}
                            control={control}
                        />
                    </Grid>
                )}
            </Grid>
            <Accordion sx={{ marginTop: 5 }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">{t("DATA_EXPORT_QUESTIONNAIRES")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Paper>
                        <DataGrid
                            rows={surveyRows}
                            columns={[
                                { field: 'id', headerName: t("GENERAL_INDEX"), flex: 1 },
                                { field: 'surveyName', headerName: t("GENERAL_FIELDS"), flex: 3 }
                            ]}
                            rowSelectionModel={selectedSurveyRows}
                            onRowSelectionModelChange={(newSelection) => handleSurveyRowSelectionChange(newSelection as number[])}
                            checkboxSelection
                            hideFooter
                            pageSizeOptions={[25, 50, 100]}
                            sx={{ border: 0 }}
                        />
                    </Paper>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">{t("DATA_EXPORT_SURVEY_VARIABLES")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Paper>
                        <DataGrid
                            rows={sessionRows}
                            columns={[
                                { field: 'id', headerName: t("GENERAL_INDEX"), flex: 1 },
                                { field: 'variableName', headerName: t("GENERAL_FIELDS"), flex: 1 },
                                { field: 'description', headerName: t("GENERAL_DESCRIPTION"), flex: 2 }
                            ]}
                            rowSelectionModel={selectedSessionRows}
                            onRowSelectionModelChange={(newSelection) => handleSessionRowSelectionChange(newSelection as number[])}
                            checkboxSelection
                            pageSizeOptions={[25, 50, 100]}
                            hideFooter
                            sx={{ border: 0 }}
                        />
                    </Paper>

                </AccordionDetails>
            </Accordion>
            <Grid container justifyContent="flex-end">
                <Grid item xs={5} sm={3} md={2} sx={{ marginTop: 3, marginBottom: 3 }}>
                    <RoundButton
                        size="small"
                        variant="contained"
                        color="success"
                        sx={{
                            width: "100%", color: "#fff", backgroundColor: "#03b2ff",
                            "&:hover": {
                                backgroundColor: "#027CB2"
                            },
                        }}
                        onClick={handleSubmit(onSubmit)}
                        disabled={isButtonDisabled()}
                        title={t("DE_EXPORT")}
                    >
                        {loading ? <CircularProgress size={25} /> : t("DE_EXPORT")}
                    </RoundButton>
                </Grid>
            </Grid>
        </Container >
    );
}

export default observer(DataExportDashboard);
