import { Box, Breadcrumbs, IconButton, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';

import { format, formatDistance } from "date-fns";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useStore } from "../../../../../stores/store";
import { RoundButton } from "../../../../../_styles/StyledButtons";
import {
    SessionStatus,
    SessionTimeline,
} from "../../../../../data/models/session";
import DashboardActions from "./DashboardActions";
import BaselineResults from "./BaselineResults";
import { ProcessDetails } from "../../../../../data/models/process";
import SessionQrCode from "./SessionQrCode";
import { SessionOverviewContext } from "./SessionOverview";
import SessionClientInfo from "./SessionClientInfo";
import "./styles.css";

interface Props {
    selectedTimeline: SessionTimeline | null;
    onTimelineChange: (timeline: SessionTimeline) => void;
}

function SessionDashboardHeader({ selectedTimeline, onTimelineChange }: Props) {
    const { width } = useContext(SessionOverviewContext);
    const { t } = useTranslation();
    const { sessionStore, authStore, modalStore, institutionStore, processStore, sidebarStore } = useStore();
    const { session, loadAssessorSession, getSessionTimelines } = sessionStore;
    const { user } = authStore;
    const { openModal } = modalStore;
    const { selectedUserInstitution } = institutionStore;
    const { getProcessList } = processStore;
    const { openSidebar } = sidebarStore;

    const [timelineList, setTimelineList] = useState<SessionTimeline[]>([]);
    const [numOfTimelines, setNumOfTimelines] = useState<number>(0);
    const [selectedProcess, setSelectedProcess] = useState<ProcessDetails | null>(null);

    const navigate = useNavigate();

    const showClientInfo = () => {
        openSidebar(<SessionClientInfo />, "Client info");
    }

    useEffect(() => {
        const fetchData = async () => {
            if (selectedUserInstitution) {
                const processes = await getProcessList(selectedUserInstitution.institutionId);
                if (processes.length > 0) {
                    setSelectedProcess(processes[0]);
                }
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUserInstitution]);

    useEffect(() => {
        const fetchData = async () => {
            if (session) {
                const timelines = await getSessionTimelines(session.sessionId);
                setTimelineList(timelines);
                let currentTimeline = null;

                const completedTimelines = timelines.filter((t) =>
                    isTimelineCompleted(t)
                ).length;
                if (numOfTimelines !== completedTimelines) {
                    currentTimeline = timelines.find(
                        (timeline) => timeline.timelineName === session?.timeline
                    );
                    setNumOfTimelines(completedTimelines);
                } else {
                    currentTimeline = timelines.find(
                        (timeline) =>
                            timeline.timelineName ===
                            (selectedTimeline?.timelineName || session?.timeline)
                    );
                }

                if (currentTimeline) {
                    onTimelineChange(currentTimeline);
                }
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session]);

    useEffect(() => {
        if (session?.currentStep === "BL_COMPLETED" && !modalStore.modal.open && user?.id === session.assessorId) {
            openModal(<BaselineResults />, { size: "sm", persistent: true })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session])

    const isSameOrPastDate = (date: Date) => {
        const currentDate = new Date();
        return (
            date.getFullYear() === currentDate.getFullYear() &&
            date.getMonth() === currentDate.getMonth() &&
            date.getDate() === currentDate.getDate()
        ) || date < currentDate;
    };
    
    const isActive = !!session?.timelineStartTime && isSameOrPastDate(new Date(session.timelineStartTime));
    

    const noShow =
        session?.currentStep === "CLIENT_NO_SHOW_UP" &&
        session.status === "ABORT";

    const isTimelineCompleted = (timeline: SessionTimeline) => {
        if (selectedProcess) {
            const timelines = selectedProcess.timelines.map(t => t.name);
            const currentTimelineIndex = timelines.indexOf(session?.timeline!);
            const passedTimelineIndex = timelines.indexOf(timeline.timelineName);

            if (currentTimelineIndex !== -1 || passedTimelineIndex !== -1) {
                return currentTimelineIndex >= passedTimelineIndex;
            }
        }
    };

    const onSurveyStart = async (surveyId: string) => {
        if (session) {
            await loadAssessorSession(session.sessionId);
            navigate(
                `/timeline/${session?.timelineId}/survey/${surveyId}/session/${session?.sessionId}?lang=${session?.languageId}`,
                {
                    state: {
                        redirect: `/timeline/${session?.timelineId}/session/${session?.sessionId}?clientId=${session?.clientId}`,
                    },
                }
            );
        }
    };

    return (
        <>
            {(session?.status === "EXCLUDED" || session?.status === "BL_ONLY" || session?.status === SessionStatus.EXCLUDED_SUICIDALITY) && (
                <Box
                    sx={{
                        bgcolor: "#e76f51",
                        textAlign: "center",
                        p: "4px 0",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                    }}
                >
                    <Typography sx={{ color: "#fff", fontSize: "13px", fontWeight: 500 }}>
                        {t("SESSION_EXC_LABEL", {
                            bl: session.status === "BL_ONLY" ? "(Baseline only)" :
                                session.status === SessionStatus.EXCLUDED_SUICIDALITY ? "(Excluded suicidality)" : ""
                        })}
                    </Typography>
                </Box>
            )}
            {(session?.status === SessionStatus.EMERGENCY_HOLD) && (
                <Box
                    sx={{
                        bgcolor: "#ffc107",
                        textAlign: "center",
                        p: "4px 0",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                    }}
                >
                    <Typography sx={{ color: "#fff", fontSize: "13px", fontWeight: 500 }}>
                        {t("SESSION_IS_HOLD")}
                    </Typography>
                </Box>
            )}
            {(session?.status === SessionStatus.DEACTIVATED) && (
                <Box
                    className="session-terminate-container"
                >
                    <Typography sx={{ color: "#fff", fontSize: "13px", fontWeight: 500 }}>
                        {t("SESSION_IS_DEACTIVATED")}
                    </Typography>
                </Box>
            )}
            {noShow && (
                <Box
                    className="session-terminate-container"
                >
                    <Typography sx={{ color: "#fff", fontSize: "13px", fontWeight: 500 }}>
                        {t("SESSION_TERMINATE")}
                    </Typography>
                </Box>
            )}
            {!isActive &&
                session &&
                session?.status !== SessionStatus.EMERGENCY_HOLD &&
                session.currentStep === "SESSION_LOADED" &&
                session.sessionStartTime && (
                    <Box sx={{ bgcolor: "#ffa62b", textAlign: "center", p: "4px 0" }}>
                        <Typography
                            sx={{ color: "#fff", fontSize: "13px" }}
                            title={format(
                                new Date(session.sessionStartTime),
                                "dd MMMM, yyyy 'at' HH:mm"
                            )}
                        >
                            Session starts{" "}
                            {formatDistance(new Date(session.sessionStartTime), new Date(), {
                                addSuffix: true,
                            })}{" "}
                            (
                            {format(
                                new Date(session.sessionStartTime),
                                "dd MMMM, yyyy 'at' HH:mm"
                            )}
                            )
                        </Typography>
                    </Box>
                )}
            {
                isActive &&
                session?.status !== SessionStatus.EMERGENCY_HOLD &&
                session?.status !== SessionStatus.DEACTIVATED &&
                session?.status !== SessionStatus.BLONLY &&
                session?.status !== SessionStatus.EXCLUDED_SUICIDALITY &&
                ["SESSION_LOADED", "AWAITING_2ND_CONSENT_AGREEMENT"].includes(session?.currentStep ?? "") &&
                user?.id === session?.assessorId &&
                <Box sx={{ bgcolor: "#0077b6", textAlign: "center", p: "4px 0" }}>
                    <Typography
                        sx={{ color: "#fff", fontSize: "13px", fontWeight: 600 }}
                    >
                        {["SESSION_LOADED"].includes(session?.currentStep ?? "") && t("SESSION_IC_ASSESSOR")}
                        {["AWAITING_2ND_CONSENT_AGREEMENT"].includes(session?.currentStep ?? "") &&
                            session.isImpairmentFormCompleted &&
                            (!session.mustCompleteAssessmentOfSuicide || session.isAssessmentOfSuicideFormCompleted) &&
                            t("SESSION_IC2_ASSESSOR")}
                    </Typography>
                </Box >
            }
            {
                isActive &&
                session?.status !== SessionStatus.EMERGENCY_HOLD &&
                session?.status !== SessionStatus.DEACTIVATED &&
                session?.status !== SessionStatus.BLONLY &&
                session?.status !== SessionStatus.EXCLUDED_SUICIDALITY &&
                session?.currentStep === "AWAITING_2ND_CONSENT_AGREEMENT" &&
                (
                    !session.isImpairmentCompleted ||
                    !session.isImpairmentFormCompleted ||
                    (session.mustCompleteAssessmentOfSuicide && !session.isAssessmentOfSuicideFormCompleted)
                ) &&
                user?.id === session?.assessorId &&
                <Box sx={{ bgcolor: "#0077b6", textAlign: "center", p: "4px 0" }}>
                    <Typography
                        sx={{ color: "#fff", fontSize: "13px", fontWeight: 600 }}
                    >
                        {!session.isImpairmentCompleted && t("IMPAIRMENT_PENDING_BEFORE_IC2")}
                        {session.isImpairmentCompleted && !session.isImpairmentFormCompleted && t("IMPAIRMENT_DIALOG_PENDING_BEFORE_IC2")}
                        <br /><br />
                        {session.mustCompleteAssessmentOfSuicide && !session.isAssessmentOfSuicideFormCompleted && t("ATS_CONCLUSION_PENDING_BEFORE_IC2")}
                    </Typography>
                </Box >
            }
            {session?.status !== SessionStatus.EMERGENCY_HOLD &&
                session?.status !== SessionStatus.DEACTIVATED &&
                session?.impairmentCanBeStarted &&
                !session?.isImpairmentCompleted &&
                session.assessorId === user?.id &&
                <Box
                    className="overview-alert"
                >
                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                        <Typography mr={2} color="white">
                            {t("SESSION_ASSESSOR_SURVEY")} Impairment Questionnaire
                        </Typography>
                        <RoundButton
                            variant="contained"
                            color="info"
                            onClick={() => onSurveyStart(session?.impairmentSurveyId)}
                        >
                            {t("GENERAL_START")}
                        </RoundButton>
                    </Box>
                </Box>
            }
            {
                session?.status !== SessionStatus.EMERGENCY_HOLD &&
                session?.status !== SessionStatus.DEACTIVATED &&
                // session?.status !== SessionStatus.BLONLY &&
                session?.currentStep === "ASSESSMENT_OF_SUICIDE_ACTIVE" &&
                session.mustCompleteAssessmentOfSuicide &&
                !session.isAssessmentOfSuicideCompleted &&
                session.assessorId === user?.id && (
                    <Box
                        className="overview-alert critical-alert"
                    >
                        <Box display="flex" justifyContent="flex-end" alignItems="center">
                            <Typography mr={2} color="white">
                                {t("SESSION_ASSESSOR_SURVEY")} Assessment of Thoughts of Suicide
                            </Typography>
                            <RoundButton
                                variant="contained"
                                color="error"
                                onClick={() => onSurveyStart(session?.assessmentOfSuicideSurveyId!)}
                            >
                                {t("GENERAL_START")}
                            </RoundButton>
                        </Box>
                    </Box>
                )
            }
            <Box className="dashboard-header">
                <Box display={"flex"} justifyContent={"space-between"} width={width <= 1100 ? "100%" : "auto"}>
                    <Box>
                        <Box display={"flex"} alignItems={"flex-end"}>
                            <Typography component="h1" fontWeight={500} fontSize={28}>
                                {selectedProcess?.name}
                            </Typography>
                        </Box>
                        <Breadcrumbs
                            sx={{ mt: "4px" }}
                            separator={<NavigateNextIcon fontSize="small" />}
                            aria-label="breadcrumb"
                        >
                            {timelineList
                                .filter((t) => isTimelineCompleted(t))
                                .map((timeline) => (
                                    <Box
                                        key={timeline.timelineId}
                                        color={"#023047"}
                                        sx={{
                                            cursor: "pointer",
                                            p: "0 8px",
                                            pb: "2px",
                                            borderRadius: "2rem",
                                            bgcolor:
                                                selectedTimeline?.timelineId === timeline.timelineId
                                                    ? "#02304724"
                                                    : "transparent",
                                        }}
                                        onClick={() => onTimelineChange(timeline)}
                                    >
                                        <Typography sx={{ fontSize: "13px", fontWeight: "500" }}>
                                            {timeline.timelineName}
                                        </Typography>
                                    </Box>
                                ))}
                        </Breadcrumbs>
                    </Box>
                    {width <= 1100 &&
                        <Box display={"flex"} justifyContent={"flex-end"}>
                            <SessionQrCode session={session!} sx={{ border: "none !important" }} />
                            <Box>
                                <IconButton className="session-client-info-btn" onClick={showClientInfo}>
                                    <AccountBoxRoundedIcon sx={{ fontSize: "2rem" }} />
                                    <Typography fontSize={"0.75rem"}>Client info</Typography>
                                </IconButton>
                            </Box>
                        </Box>}
                </Box>
                <SessionQrCode session={session!} />
                {width > 1100 && <DashboardActions isActive={isActive} />}
            </Box>
        </>
    );
}

export default observer(SessionDashboardHeader);
