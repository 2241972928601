import { makeAutoObservable, runInAction } from "mobx";
import { User, UserFormValues } from "../data/models/user";
import { router } from "../router/Routes";
import agent from "../utils/agent";
import { getDecodedToken } from "../utils/jwtDecoder";
import { store } from "./store";
import { toast } from "react-toastify";

type SessionState = "active" | "idle";

export default class AuthStore {
    user: User | null = null;
    sessionState: SessionState = "active";
    loading: boolean = false;

    constructor() {
        makeAutoObservable(this)
    }

    get isLoggedIn() {
        return !!this.user;
    }

    updateState = (state: SessionState) => {
        runInAction(() => {
            this.sessionState = state;
        });
    }

    login = async (creds: UserFormValues, redirect: string | null = null) => {
        store.loadingStore.startLoading(this.login);
        try {
            const user = await agent.Auth.login(creds);
            const decodedToken = getDecodedToken(user.token);

            if (process.env.REACT_APP_ENVIRONMENT === "development" || process.env.REACT_APP_ENVIRONMENT === "test") {

                if (user.requirePasswordChange) {
                    router.navigate(`/reset-password/?token=${encodeURIComponent(user.resetPasswordToken)}&email=${encodeURIComponent(user.email)}`);
                    store.loadingStore.stopLoading(this.login);
                    return;
                }

                store.commonStore.setToken(user.token);
                await store.profileStore.getUserTenants();

                if (store.profileStore.userTenantList.length > 0) {
                    store.commonStore.userConfig();
                    await this.getCurrentUser();
                } else {
                    store.authStore.user = user;
                    router.navigate('/disabled');
                }
                store.loadingStore.stopLoading(this.login);
                router.navigate(redirect);
            }
            else if (!decodedToken.twoFactorEnabled) {
                router.navigate(`/two-factor-confirmation?token=${user.token}`, { state: { redirect: redirect } });
                store.loadingStore.stopLoading(this.login);
            }
            else {
                router.navigate(`/two-factor-authorization?token=${user.token}`, { state: { redirect: redirect } });
                store.loadingStore.stopLoading(this.login);
            }
        } catch (error) {
            store.loadingStore.stopLoading(this.login);
            throw error;
        }
    }

    extendUserSession = async () => {
        try {
            const user = await agent.Auth.refreshSession();
            if (user.token !== null) {
                store.commonStore.setToken(user.token);
                this.getCurrentUser();
            }
        } catch (error) {
            throw error;
        }
    }

    isSessionExpired = () => {
        const token = sessionStorage.getItem('jwt');
        const decodedToken = getDecodedToken(token);
        const currentTime = Math.floor(Date.now() / 1000);
        const remainingTimeInMillis = (decodedToken.exp - currentTime) * 1000;
        const requestPeriod = 25 * 1000 // 25 seconds

        if (remainingTimeInMillis > requestPeriod) {
            setTimeout(() => {
                if (this.sessionState === "active") {
                    this.extendUserSession();
                } else {
                    this.logout();
                    toast.info("Your session has expired", { autoClose: false, toastId: "sessionExpire" });
                }
            }, remainingTimeInMillis - requestPeriod);
        } else if (remainingTimeInMillis > 0 && remainingTimeInMillis < requestPeriod) {
            if (this.sessionState === "active") {
                this.extendUserSession();
            } else if (this.sessionState === "idle") {
                this.logout();
                toast.info("Your session has expired", { autoClose: false, toastId: "sessionExpire" });
            }
        } else {
            return true;
        }

        return false;
    }

    getCurrentUser = async () => {
        try {
            // if (this.isSessionExpired()) {
            //     this.logout();
            //     toast.info("Your session has expired");
            //     return;
            // }

            const user = await agent.Auth.current();
            await store.profileStore.getUserTenants();
            getDecodedToken(user.token);
            if (store.profileStore.userTenantList.length === 0) {
                router.navigate('/disabled');
            } else {
                await store.profileStore.getUserTenants();
                store.commonStore.currentUserConfig();
            }
            runInAction(() => {
                this.user = user;
            });
        } catch (error) {
            throw error;
        }
    }

    logout = () => {
        store.commonStore.clearSession();
        this.user = null;
        router.navigate('/login');
    }
}