import { Box, Grid, Typography } from "@mui/material";

import { useState } from "react";
import { toast } from "react-toastify";
import ClientProcessTable from "./ClientProcessTable";
import { RoundButton } from "../../../_styles/StyledButtons";
import ClientSessionForm from "./ClientSessionForm";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Event } from "react-big-calendar";

interface ClientProcessProps {
    back: () => void;
    display: boolean;
}



function ClientProcess({ back, display }: ClientProcessProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isAdded, setIsAdded] = useState(false);
    const [showMsg, setShowMsg] = useState(false);

    const handleBack = () => {
        setShowMsg(false);
        back();
    }

    function handleSubmit(): void {
        const currentPath = window.location.pathname;
        const isCreatingClient = currentPath.endsWith("/create");

        if (isCreatingClient) {
            toast.success(t("TOAST_CLIENT_CREATE"));
        } else {
            toast.success(t("TOAST_CLIENT_UPDATE"));
        }

        navigate("/users");
    }

    return (
        <Box sx={{ display: display ? "block" : "none" }}>
            <Grid
                container
                columnSpacing={1}
                marginTop="1rem"
                marginBottom="5px"
            >
                <Grid item xs={12}>
                    <ClientProcessTable setAdded={setIsAdded} added={isAdded} showInfo={showMsg} setShowInfo={setShowMsg} />
                </Grid>
                <Grid item xs={12}>
                    <ClientSessionForm visible={isAdded} />
                </Grid>
                <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    margin={"1rem 3rem"}
                >
                    <RoundButton
                        variant="text"
                        color="inherit"
                        onClick={handleBack}
                        sx={{ marginRight: "1rem" }}
                    >
                        <Typography fontSize={"13px"}>{t("GENERAL_BACK")}</Typography>
                    </RoundButton>
                    <RoundButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        title="submit"
                        disabled={!isAdded}
                        onClick={handleSubmit}
                    >
                        <Typography fontSize={"13px"}>{t("GENERAL_SUBMIT")}</Typography>
                    </RoundButton>
                </Grid>
            </Grid>
        </Box>
    );
}

export default ClientProcess;